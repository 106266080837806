import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _91slug_93ZQvUgt2qFfMeta } from "/app/pages/focus/[slug].vue?macro=true";
import { default as imprintXQz1X9eYwAMeta } from "/app/pages/imprint.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46id_93iQfKVVw95bMeta } from "/app/pages/preview/[langIso]/Pages/[...id].vue?macro=true";
import { default as privacyg3gwBRGXatMeta } from "/app/pages/privacy.vue?macro=true";
import { default as _91_91version_93_93IxVaCrGjfzMeta } from "/app/pages/we/preview/[langIso]/Pages/[id]/[[version]].vue?macro=true";
export default [
  {
    name: "slug___de",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___it",
    path: "/it/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "focus-slug___de",
    path: "/focus/:slug()",
    component: () => import("/app/pages/focus/[slug].vue").then(m => m.default || m)
  },
  {
    name: "focus-slug___en",
    path: "/en/focus/:slug()",
    component: () => import("/app/pages/focus/[slug].vue").then(m => m.default || m)
  },
  {
    name: "focus-slug___it",
    path: "/it/focus/:slug()",
    component: () => import("/app/pages/focus/[slug].vue").then(m => m.default || m)
  },
  {
    name: "imprint___de",
    path: "/impressum",
    component: () => import("/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "imprint___en",
    path: "/en/imprint",
    component: () => import("/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "imprint___it",
    path: "/it/imprint",
    component: () => import("/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "preview-langIso-Pages-id___de",
    path: "/preview/:langIso()/Pages/:id(.*)*",
    component: () => import("/app/pages/preview/[langIso]/Pages/[...id].vue").then(m => m.default || m)
  },
  {
    name: "preview-langIso-Pages-id___en",
    path: "/en/preview/:langIso()/Pages/:id(.*)*",
    component: () => import("/app/pages/preview/[langIso]/Pages/[...id].vue").then(m => m.default || m)
  },
  {
    name: "preview-langIso-Pages-id___it",
    path: "/it/preview/:langIso()/Pages/:id(.*)*",
    component: () => import("/app/pages/preview/[langIso]/Pages/[...id].vue").then(m => m.default || m)
  },
  {
    name: "privacy___de",
    path: "/privacy",
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "privacy___it",
    path: "/it/privacy",
    component: () => import("/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "we-preview-langIso-Pages-id-version___de",
    path: "/we/preview/:langIso()/Pages/:id()/:version?",
    component: () => import("/app/pages/we/preview/[langIso]/Pages/[id]/[[version]].vue").then(m => m.default || m)
  },
  {
    name: "we-preview-langIso-Pages-id-version___en",
    path: "/en/we/preview/:langIso()/Pages/:id()/:version?",
    component: () => import("/app/pages/we/preview/[langIso]/Pages/[id]/[[version]].vue").then(m => m.default || m)
  },
  {
    name: "we-preview-langIso-Pages-id-version___it",
    path: "/it/we/preview/:langIso()/Pages/:id()/:version?",
    component: () => import("/app/pages/we/preview/[langIso]/Pages/[id]/[[version]].vue").then(m => m.default || m)
  }
]